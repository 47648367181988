<template>
  <div class="card">
    <Toast position="top-center" group="info" />
    <h5>Investments</h5>
    <ProgressBar
      v-if="loading"
      class="progress-bar"
      mode="indeterminate"
    />
    <DataTable
      v-else
      :value="investData"
      data-key="id"
      paginator
      :rows="itemsPerPage"
      lazy
      :total-records="totalItems"
      @page="changePage"
    >
      <Column class="w-4" header="Project" field="auCapital.tokenBase.nameEng">
        <template #body="slot">
          <span
            class="clickable"
            @click="goToCapital(slot.data?.auCapital.id)"
          >
            {{ slot.data?.auCapital?.tokenBase?.nameEng }}
          </span>
        </template>
      </Column>
      <Column class="w-3" header="User" field="user.email">
        <template #body="slot">
          <span
            class="clickable"
            @click="goToUser(slot.data?.user?.id)"
          >
            {{ slot.data?.user?.email ?? "-" }}
          </span>
        </template>
      </Column>
      <Column class="w-3" header="Investment amount" field="value"/>
      <Column class="w-3" header="Date">
        <template #body="slot">
          {{ formatDate(slot.data.createdAt) }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: "ManagerInvestments",
  data() {
    return {
      loading: false,
      page: 1,
      itemsPerPage: 30,
      investData: [],
      totalItems: 0
    };
  },
  async mounted() {
    await this.geInvestData();
  },
  methods: {
    async geInvestData() {
      this.loading = true;
      const headers = {
        accept: "application/ld+json",
        "Content-Type": "application/ld+json",
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        type: "token_capital_buy"
      }
      try {
        const resp = await this.axios.get("/api/token_events", { params, headers });
        this.investData = resp.data["hydra:member"];
        this.totalItems = resp.data["hydra:totalItems"];
      }
      catch (e) {
        this.showError("Error when receiving a list of investments requests");
      }
      this.loading = false;
    },

    formatDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm ")
    },

    async changePage(event) {
      this.page = event.page + 1;
      await this.geInvestData()
    },
    goToCapital(id) {
      this.$router.push({ name: "capital", params: { id: id }});
    },
    goToUser(id) {
      this.$router.push({ name: "MarketingUsers", params: { id: id }});
    },
    showError(message) {
      this.$toast.add({
        severity: "error",
        summary: "Error",
        detail: message,
        group: "info",
        life: 3000,
      })
    },

    showMessage(message) {
      this.$toast.add({
        severity: "success",
        summary: "Success",
        detail: message,
        group: "info",
        life: 3000,
      })
    },
  }
}
</script>

<style scoped>

</style>